import React from "react";
import { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
type Props = {
  ConfirmationObject: {
    ShowPopup: boolean;
    Heading: string;
    Message: string;
    ID: number;
    SelectedID: number;
    OtherID: number;
    Object: any;
  };
  ConfirmationModalCallBack: any;
};
function ConfirmationModal(props: Props) {
  const [ShowPopup, setShowPopup] = useState(
    props.ConfirmationObject.ShowPopup
  );
  function ClosePopUp(value: boolean) {
    props.ConfirmationModalCallBack({
      show: false,
      isConfirm: value,
      ID: props.ConfirmationObject.ID,
      SelectedID: props.ConfirmationObject.SelectedID,
      OtherID: props.ConfirmationObject.OtherID,
      Object: props.ConfirmationObject.Object,
    });
  }
  return (
    <div>
      {props.ConfirmationObject.ShowPopup && (
        <Modal show={true}>
          <Modal.Header closeButton onClick={() => ClosePopUp(false)}>
            <Modal.Title>{props.ConfirmationObject.Heading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="del--confirm"
              dangerouslySetInnerHTML={{
                __html: props.ConfirmationObject.Message,
              }}
            ></div>
            <div className="modal--button del--modal__btn">
              <a onClick={() => ClosePopUp(false)}>No</a>
              <a onClick={() => ClosePopUp(true)}>Yes</a>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

export default ConfirmationModal;
