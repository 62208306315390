import "bootstrap/dist/css/bootstrap.min.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AppContext from "../Context/Context";
import axiosAuth from "../axios/Interceptors";
import React, { useState, useEffect, useRef } from "react";
import { useGlobalState } from "../Context/GlobalStateContext";
export default function Settings() {
  const globalState = useGlobalState();
  const [FirstName, SetFirstName] = useState("");
  const [LastName, SetLastName] = useState("");

  const [CurrentPassword, SetCurrentPassword] = useState("");
  const [Password, SetPassword] = useState("");
  const [ConfirmPassword, SetConfirmPassword] = useState("");
  const [ErrorMessage, SetErrorMessage] = useState("");

  useEffect(() => {
    GetMyUserDetails()
  }, []);

  function GetMyUserDetails() {
      let object = {};
      axiosAuth
        .post(
          "https://authapi.manyge.com/api/SiteUser/GetMyUserDetails",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            SetFirstName(response.data.Data[0].FirstName);
            SetLastName(response.data.Data[0].LastName);

          } else {
            AppContext.ShowNotification("error", response.data.Message);
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
  }


  function UpdateUserProfileData() {
    if (FirstName !== "") {
      let object:any = {};
      object.firstname = FirstName;
      object.lastname = LastName;
      axiosAuth
        .post(
          "https://authapi.manyge.com/api/SiteUser/UpdateUserProfileData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            AppContext.GetNewJWTTokenFromRefreshToken(false);
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Success",
              Message: "Your Name changed successfully.",
            });
          } else {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Error",
              Message: response.data.Message,
            });
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Error",
        Message: "Please Enter First Name",
      });
    }
  }

  function CheckValidation() {
    if (CurrentPassword !== "" && Password !== "" && ConfirmPassword !== "") {
      if (PasswordValidation(1)) {
        if (Password === ConfirmPassword) {
          UpdateUserPasswordData();
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Error",
            Message: "Password and confirm password do not match.",
          });
        }
      }
    } else {
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Error",
        Message: "All fields are required.",
      });
    }
  }

  function UpdateUserPasswordData() {
    let object:any = {};
    object.currentpassword = CurrentPassword;
    object.password = Password;
    object.confirmpassword = ConfirmPassword;
    axiosAuth
      .post(
        "https://authapi.manyge.com/api/SiteUser/UpdateUserPasswordData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          SetCurrentPassword("");
          SetPassword("");
          SetConfirmPassword("");
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Success",
            Message: 'Your password changed successfully.',
          });
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Error",
            Message: response.data.Message,
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function PasswordValidation(type:any) {
    let Okay = true;
    if (type == 1 || type < 1) {
      if (Password == "") {
        Okay = false;
        SetErrorMessage("");
      } else {
        var DataPassword = AppContext.PTCheckPasswordValidation(Password);
        if (!DataPassword.Valid) {
          Okay = false;
          SetErrorMessage(DataPassword.Message);
        } else {
          SetErrorMessage("");
        }
      }
    }

    return Okay;
  }

  return (
    <div className="settings--main">
      <Tabs
        defaultActiveKey="BasicInfo"
        transition={false}
        id="noanim-tab-example"
        className="settings--tab"
      >
        <Tab eventKey="BasicInfo" title="Basic Info">
          <div className="modal--create__inputs mt--30">
            <span className="input--text">First Name</span>
            <input
              type="text"
              className="input--type__text"
              value={FirstName}
              onChange={(e) => SetFirstName(e.target.value)}
            />
          </div>
          <div className="modal--create__inputs mt--30">
            <span className="input--text">Last Name</span>
            <input
              type="text"
              className="input--type__text"
              value={LastName}
              onChange={(e) => SetLastName(e.target.value)}
            />
          </div>
          <div className="modal--create__inputs mt--20">
            <div className="modal--button">
              <a onClick={() => UpdateUserProfileData()}>Update Info</a>
            </div>
          </div>
        </Tab>
        <Tab eventKey="ChangePassword" title="Change Password">
          <div className="modal--create__inputs mt--30">
            <span className="input--text">Current Password</span>
            <input
              type="text"
              value={CurrentPassword}
              className="input--type__text"
              onChange={(e) => SetCurrentPassword(e.target.value)}
            />
          </div>
          <div className="modal--create__inputs mt--30">
            <span className="input--text">New Password</span>
            <input
              type="text"
              className="input--type__text"
              value={Password}
              onChange={(e) => SetPassword(e.target.value)}
              onKeyUp={() => PasswordValidation(1)}
            />
          </div>
          <div className="modal--create__inputs mt--30">
            <span className="input--text">Confirm Password</span>
            <input
              type="text"
              value={ConfirmPassword}
              className="input--type__text"
              onChange={(e) => SetConfirmPassword(e.target.value)}
            />
          </div>
          <div className="modal--create__inputs mt--20">
            <div className="modal--button">
              <a onClick={() => CheckValidation()}>Update Password</a>
            </div>
          </div>
          <div
            className="login--strong__passcode"
            dangerouslySetInnerHTML={{ __html: ErrorMessage }}
          ></div>
        </Tab>
      </Tabs>
    </div>
  );
}
