import React, { useLayoutEffect, useRef } from "react";
import { useState, useEffect } from "react";

import {
  DownloadClientsDataInExcel,
  formateDate,
  SortTheList,
} from "../Shared/Utility";
import {
  useReactTable,
  ColumnResizeMode,
  getCoreRowModel,
  ColumnDef,
  flexRender,
  ColumnResizeDirection,
} from "@tanstack/react-table";
import moment from "moment";
import ColumnFilterCheckboxList from "./ColumnFilterCheckboxList";
type TableGridViewProps = {
  GridData: any;
};

function TableGridView(props: TableGridViewProps) {
  const [columns, setcolumns] = useState<any>([]);

  const [columnResizeMode, setColumnResizeMode] =
    React.useState<ColumnResizeMode>("onChange");

  const [columnResizeDirection, setColumnResizeDirection] =
    React.useState<ColumnResizeDirection>("ltr");

  const [colNameList, setColNameList] = useState<any>([]);
  let table = useReactTable({
    data: [],
    columns,
    columnResizeMode,
    columnResizeDirection,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
  });

  useEffect(() => {
    if (props.GridData && props.GridData.length > 0) {
      const columnsList = getColumns(props.GridData);
      const thiscolumns:any = getResizerColumns(props.GridData);
      setcolumns(thiscolumns);

      const deepCopy1 = JSON.parse(JSON.stringify(props.GridData));
      const deepCopy2 = JSON.parse(JSON.stringify(props.GridData));
      setThisGridListDataWithoutFilters(deepCopy1);
      setThisGridListData(deepCopy2);
      thisGridListDataRef.current = deepCopy2;

      setColNameList(columnsList);
    }
  }, []);

  const getColumns = (data: Array<any>): { Name: string }[] => {
    if (data && data.length > 0) {
      const uniqueColumns = Array.from(new Set(data.flatMap(Object.keys)));
      return uniqueColumns.map((col) => ({ Name: col }));
    }
    return [];
  };
  const getResizerColumns = (data: Array<any>) => {
    if (data && data.length > 0) {
      const uniqueColumns = Array.from(new Set(data.flatMap(Object.keys)));
      return uniqueColumns.map((col) => ({
        accessorKey: col,
        header: col,
        size: 100,
        minSize: 50,
        maxSize: 200,
        resizable: true,
      }));
    }
    return [];
  };

  ///--States for columnFilter components---///
  const [clearFilters, setClearFilters] = useState(false);
  const [refreshFilters, setRefreshFilters] = useState(false);
  const [getFilteredLists, setGetFilteredLists] = useState(false);
  const [selectedColumnName, setSelectedColumnName] = useState("");
  const [thisGridListDataWithoutFilters, setThisGridListDataWithoutFilters] =
    useState<any>([]);
  const [thisGridListData, setThisGridListData] = useState<any>([]);
  const thisGridListDataRef = useRef<any | null>(null);
  thisGridListDataRef.current = thisGridListData;
  const [clickPosition, setClickPosition] = useState<any>({ x: null, y: null });
  function ShowColumnFilterList(event: any, Name: string) {
    if (Name === selectedColumnName) {
      setSelectedColumnName("");
    } else {
      setClickPosition({ x: event.clientX, y: event.clientY });
      setSelectedColumnName(Name);
    }
  }

  function filterCheckboxListCallback(FilterData: any) {
    setSelectedColumnName("");
    let l_data = []; //[...COPAInvoicesGridData] as any;
    l_data = [...FilterData];
    //setCOPAInvoicesGridData([...l_data]);

    setThisGridListData([...FilterData]);
    thisGridListDataRef.current = [...FilterData];
  }

  function ClearAllFilters() {
    const deepCopy = JSON.parse(JSON.stringify(thisGridListDataWithoutFilters));
    setThisGridListData(deepCopy);
    thisGridListDataRef.current = deepCopy;
    setClearFilters(!clearFilters);
  }

  //--States for columnFilter components---///

  const [filters, setFilters] = useState(["", "D"]);

  // useEffect(() => {
  //   SetSortingImage();
  // }, [retailerListData]);

  useLayoutEffect(() => {
    SetSortingImage(); // Called after DOM update but before paint
  }, [colNameList]);

  function SetSortingImage() {
    if (colNameList && colNameList.length > 0) {
      for (let i = 0; i < colNameList.length; i++) {
        if (document.getElementById("imgSort" + colNameList[i].Name)) {
          (
            document.getElementById(
              "imgSort" + colNameList[i].Name
            ) as HTMLInputElement
          ).innerHTML =
            '<img src="images/none.svg" width="10px" height="13px" alt="B"/>';
        }
      }
    }
    var l_strClassimgAdd =
      '<img src="images/asc.svg" width="10px" height="13px" alt="B"/>';
    if (filters[1] == "D") {
      l_strClassimgAdd =
        '<img src="images/desc.svg" width="10px" height="13px" alt="B"/>';
    }
    if (filters[0] != "")
      (
        document.getElementById("imgSort" + filters[0]) as HTMLInputElement
      ).innerHTML = l_strClassimgAdd;
  }

  function LoadWithSelectedSort(p_strColumnName: any) {
    var l_strSortExpression = filters[0];
    var l_strSortDirection = filters[1];
    if (l_strSortExpression == p_strColumnName) {
      if (l_strSortDirection == "D") {
        filters[1] = "A";
      } else {
        filters[1] = "D";
      }
    } else {
      filters[1] = "D";
    }
    filters[0] = p_strColumnName;
    HandleSortAndLoadData();

    SetSortingImage();
  }

  function HandleSortAndLoadData() {
    if (filters[0] != "") {
      var SortedGridData = SortTheList(
        thisGridListDataRef.current,
        filters[0],
        filters[1]
      );
      HandleDataLoad(SortedGridData);
    } else {
      HandleDataLoad(thisGridListDataRef.current);
    }
  }

  function HandleDataLoad(GridData: any) {
    let data: any = [...GridData];
    setThisGridListData(data);
    SetSortingImage();
  }

  ////  Sorting Ends    ////

  return (
    <>
      {thisGridListData && thisGridListData.length > 0 && (
        <ColumnFilterCheckboxList
          DataType={1}
          RefreshFilters={refreshFilters}
          ClearFilters={clearFilters}
          GridData={thisGridListData}
          ColumnName={selectedColumnName}
          ColumnFilterCheckboxListCallback={filterCheckboxListCallback}
          GetFilteredLists={getFilteredLists}
          ReturnAllFilterdLists={{}}
          ClickPosition={clickPosition}
          Orientation={"Center"}
          ColumnOrientation={[{ Name: "Name", Orientation: "Right" }]}
        />
      )}
      <div className="flex-d flex-ac flex-jse mb--10 g-export">
        <a
          className="flex-d flex-ac flex-jc"
          title="Export"
          onClick={() =>
            DownloadClientsDataInExcel("dynamictblexport", "Exported File")
          }
        >
          <img src="images/export-b.svg" width="14" height="14" />
        </a>
      </div>
      <table
        className="reuse-table"
        id="dynamictblexport"
        {...{
          style: {
            width: table.getCenterTotalSize(),
          },
        }}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup: any)  => (
            <tr key={headerGroup.id}>
              {colNameList.map((ColumnNames: any, index: any) => {
                const header = headerGroup.headers[index];
                return (
                  <th
                    key={index}
                    className="sticky-header"
                    style={{
                      width: header?.getSize(),
                    }}
                  >
                    <div className="flex-d flex-ac flex-jc flex-gap20">
                      <div className="flex-d flex-ac flex-gap10">
                        {ColumnNames.Name}
                        <a
                          onClick={(e) =>
                            ShowColumnFilterList(e, ColumnNames.Name)
                          }
                          data-function="ShowColumnFilterList"
                        >
                          <img
                            src="images/icon-search-w.svg"
                            width={15}
                            height={15}
                            alt="search"
                          />
                        </a>
                        <a
                          onClick={() => LoadWithSelectedSort(ColumnNames.Name)}
                          title={"Sort By " + ColumnNames.Name}
                        >
                          <span id={"imgSort" + ColumnNames.Name}></span>
                        </a>
                      </div>
                    </div>
                    {/* Add Resizer Handler */}
                    <div
                      onDoubleClick={() => header?.column.resetSize()}
                      onMouseDown={header?.getResizeHandler()}
                      onTouchStart={header?.getResizeHandler()}
                      className={`resizer ${header?.column.getIsResizing() ? "isResizing" : ""
                        }`}
                      style={{
                        cursor: "col-resize",
                        transform:
                          table.getState().columnSizingInfo.isResizingColumn ===
                            header?.id
                            ? `translateX(${table.getState().columnSizingInfo.deltaOffset ?? 0
                            }px)`
                            : "",
                      }}
                    />
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {thisGridListData &&
            thisGridListData.map((Item: any, index: any) => (
              <React.Fragment key={index}>
                {!Item.IsExcluded && (
                  <tr key={index}>
                    {Object.keys(Item)
                      .filter((key) => key !== "IsExcluded") // Filter out 'IsExcluded' or other keys you don't want
                      .map((Name) => {
                        const value = Item[Name];
                        const isNumber = !isNaN(value); // Check if the value is a valid number
                        const isDate = moment(
                          value,
                          moment.ISO_8601,
                          true
                        ).isValid(); // Check if it's a valid date

                        // Check if value contains percentage
                        const isPercentage =
                          typeof value === "string" && value.includes("%");
                        const isValidPercentage =
                          isPercentage && !isNaN(parseFloat(value));

                        return (
                          <td
                            key={Name}
                            className={
                              isNumber || isValidPercentage ? "text-right" : ""
                            }
                          >
                            {isDate
                              ? formateDate(value,false)
                              : value}
                          </td>
                        );
                      })}
                  </tr>
                )}
              </React.Fragment>
            ))}

          {/* {thisGridListData &&
            thisGridListData.map((Item: any, index: any) => (
              <React.Fragment key={index}>
                {!Item.IsExcluded && (
                  <tr key={index}>
                    {Object.keys(Item).map((Name) => (
                      <td key={Name}>{Item[Name]}</td>
                    ))}
                  </tr>
                )}
              </React.Fragment>
            ))} */}
        </tbody>
      </table>
    </>
  );
}

export default TableGridView;