import React, { useEffect, useState } from "react";

type ElapsedTimeProps = {
  startDateTime: Date;
};

const ElapsedTime: React.FC<ElapsedTimeProps> = ({ startDateTime }) => {
  const [elapsedTime, setElapsedTime] = useState("00:00:00");

  useEffect(() => {
    if (startDateTime) {
    const calculateElapsedTime = () => {
      const now = new Date();
      const timeDifference = now.getTime() - new Date(startDateTime).getTime();

      const hours = Math.floor(timeDifference / (1000 * 60 * 60))
        .toString()
        .padStart(2, "0");
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      )
        .toString()
        .padStart(2, "0");
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000)
        .toString()
        .padStart(2, "0");

      setElapsedTime(`${hours}:${minutes}:${seconds}`);
    };

      calculateElapsedTime(); // Initial calculation
      const interval = setInterval(calculateElapsedTime, 1000); // Update every second
      
      return () => clearInterval(interval); // Cleanup interval on unmount
    }
  }, [startDateTime]);

  return <div>{elapsedTime}</div>;
};

export default ElapsedTime;
