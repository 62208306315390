import React from "react";
import AppContext from "../Context/Context";
import { useState, useEffect, useRef } from "react";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";

import { useGlobalState } from "../Context/GlobalStateContext";
import axiosAuth from "../axios/Interceptors";
import TableGridView from "../App/DynamicGridView";
import { getEndOfMonth, getStartOfMonth } from "../Shared/Utility";
function Reporting() {
  const globalState = useGlobalState();
  const [screenID, setScreenID] = useState(0);

  const [showAnimation, setShowAnimation] = useState(false);
  const [showReportGrid, setShowReportGrid] = useState(false);
  const [reportGridData, setReportGridData] = useState<any>([]);

  const [filtersDropdownData, setFiltersDropdownData] = useState({
    ReportSectionData: [],
    ReportSectionButtonData: [],
    ProjectData: [],
    UserData: [],
  });

  const [selectedFiltersDropdownObject, setSelectedFiltersDropdownObject] =
    useState({
      projectid: 0,
      listid: 0,
      from: getStartOfMonth(),
      to: getEndOfMonth(),
      userid: 0,
    });

  useEffect(() => {
    getReportingListsData();
  }, []);

  function getReportingListsData() {
    let object = {} as any;
    object.masterbpid = 0;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/MGReporting/GetReportingListsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setFiltersDropdownData((prevState: any) => ({
            ...prevState,
            ReportSectionData: l_response[0],
            ReportSectionButtonData: l_response[1],
            ProjectData: l_response[2],
            UserData: l_response[3],
          }));
        } else {
          console.log("error", response.data.Message);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function downloadJLRReport(reportNumber: any) {
    setShowAnimation(true);
    let object = {} as any;
    object.reportnumber = reportNumber;
    object.reporttype = 1;
    object.projectid = selectedFiltersDropdownObject.projectid;
    object.listid = selectedFiltersDropdownObject.listid;
    object.from = selectedFiltersDropdownObject.from;
    object.to = selectedFiltersDropdownObject.to;
    object.userid = selectedFiltersDropdownObject.userid;
    axiosAuth
      .post(AppContext.AppBaseURL + "api/MGReporting/DownloadReport", object)
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          if(l_response && l_response[0] && l_response[0].Table){
          setReportGridData(l_response[0].Table);
          }
          setShowReportGrid(true);

          console.log(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}

      <div className="page--contents">
        <div className="page--breadcrumb">
          <a>Reporting</a>
        </div>

        <div className="page--data">
          {showReportGrid == true && (
            <>
              <button onClick={() => setShowReportGrid(false)}>Back</button>
            </>
          )}
          {showReportGrid == false && (
            <>
              <div className="flex-items msg-item">
                {filtersDropdownData.ProjectData &&
                  filtersDropdownData.ProjectData.length > 1 && (
                    <>
                      <select
                        className="input-field select-field"
                        value={selectedFiltersDropdownObject.projectid}
                        onChange={(e) =>
                          setSelectedFiltersDropdownObject(
                            (prevState: any) => ({
                              ...prevState,
                              projectid: e.target.value,
                            })
                          )
                        }
                      >
                        <option value={0}>Select Project</option>
                        {filtersDropdownData.ProjectData &&
                          filtersDropdownData.ProjectData.map(
                            (Item: any, index: any) => (
                              <option value={Item.PID} key={Item.PID}>
                                {Item.Name}
                              </option>
                            )
                          )}
                      </select>

                      <select
                        className="input-field select-field"
                        value={selectedFiltersDropdownObject.userid}
                        onChange={(e) =>
                          setSelectedFiltersDropdownObject(
                            (prevState: any) => ({
                              ...prevState,
                              userid: e.target.value,
                            })
                          )
                        }
                      >
                        <option value={0}>Select User</option>
                        {filtersDropdownData.UserData &&
                          filtersDropdownData.UserData.map(
                            (Item: any, index: any) => (
                              <option value={Item.PID} key={Item.PID}>
                                {Item.Name}
                              </option>
                            )
                          )}
                      </select>
                    </>
                  )}

                <span>
                  Start Date:
                  <input
                    type="date"
                    value={selectedFiltersDropdownObject.from}
                    onChange={(e) =>
                      setSelectedFiltersDropdownObject((prevState: any) => ({
                        ...prevState,
                        from: e.target.value,
                      }))
                    }
                  />
                </span>
                <span>
                  End Date:
                  <input
                    type="date"
                    value={selectedFiltersDropdownObject.to}
                    onChange={(e) =>
                      setSelectedFiltersDropdownObject((prevState: any) => ({
                        ...prevState,
                        to: e.target.value,
                      }))
                    }
                  />
                </span>
              </div>

              {filtersDropdownData.ReportSectionData &&
                filtersDropdownData.ReportSectionData.map(
                  (SectionItem: any, SectionIndex: any) => (
                    <React.Fragment key={SectionIndex}>
                      <div className="report-accord">
                        <div className="acco-head">{SectionItem.Name}</div>
                        <div className="acco-btns">
                          {filtersDropdownData.ReportSectionButtonData &&
                            filtersDropdownData.ReportSectionButtonData.map(
                              (Item: any, index: any) => (
                                <React.Fragment key={index}>
                                  {SectionItem.MGReportingSectionID ==
                                    Item.MGReportingSectionID && (
                                    <div className="btn-info-rep">
                                      <a
                                        onClick={() =>
                                          downloadJLRReport(Item.ReportNumber)
                                        }
                                      >
                                        <span
                                          className="btn-icon"
                                          title={Item.Info}
                                        >
                                          <img
                                            src="images/r-info.svg"
                                            width="20"
                                            height="20"
                                            alt="icon"
                                          />
                                        </span>
                                        <span className="c-line"></span>
                                        <span className="btn-txt">
                                          {Item.Name}
                                        </span>
                                      </a>
                                    </div>
                                  )}
                                </React.Fragment>
                              )
                            )}
                        </div>
                      </div>
                    </React.Fragment>
                  )
                )}
            </>
          )}

          {showReportGrid == true && reportGridData.length > 0 && (
            <>{reportGridData && <TableGridView GridData={reportGridData} />}</>
          )}

          {showReportGrid == true && reportGridData.length == 0 && (
            <>No data found.</>
          )}
        </div>
      </div>
    </>
  );
}

export default Reporting;
