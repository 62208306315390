import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axiosAuth from "../axios/Interceptors";
import AppContext from "../Context/Context";
import ElapsedTime from "./ElapsedTime";

type Props = {
  Type: number;
  TimeSpent: any;
};
export default function DisplaySpentTime(props: Props) {
  const convertMinutesToHHMM = (minutes: number): string => {
    const hours = Math.floor(minutes / 60)
      .toString()
      .padStart(2, "0");
    const mins = (minutes % 60).toString().padStart(2, "0");
    return `${hours}:${mins}`;
  };

  return (
    <>
      <span>Time Spent</span>
      <span className="total-lg-font">
        {convertMinutesToHHMM(props.TimeSpent)}
      </span>
    </>
  );
}
