import React from "react";
import { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
type Props = {
  NotificationObject: {
    ShowPopup: boolean;
    Heading: string;
    Message: string;
  };
  NotificationCallBack: any;
};
function Notification(props: Props) {
  const [ShowPopup, setShowPopup] = useState(
    props.NotificationObject.ShowPopup
  );
  function ClosePopUp() {
    props.NotificationCallBack({ show: false });
  }
  return (
    <div>
      {props.NotificationObject.ShowPopup && (
        <Modal show={true}>
          <Modal.Header closeButton onClick={() => ClosePopUp()}>
            <Modal.Title>{props.NotificationObject.Heading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="del--confirm"
              dangerouslySetInnerHTML={{
                __html: props.NotificationObject.Message,
              }}
            ></div>
            <div className="modal--button del--modal__btn">
              <a onClick={() => ClosePopUp()}>Close</a>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

export default Notification;
