import React, { useState } from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../axios/Interceptors";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";

export default function ParentDragDrop() {
  const [LoadingState, SetLoadingState] = useState(false);
  const [DragDropFeatures, SetDragDropFeatures] = useState<any>({
    ObjectID: 1,
    MaxAllowedFiles: 3,
    UploadType: 1,
    FolderName: "TestUpload",
    PTUploadedFiles: [],
  });

  const [UploadFileList, SetUploadFileList] = useState([]);
  const [TriggerChild, SetTriggerChild] = useState(false);

  function HandleDragDropCallback(ChildData:any) {
    SetUploadFileList(ChildData);
  }

  function SaveFiles() {
    if (UploadFileList && UploadFileList.length > 0) {
      let object:any = {};
      object.typeid = 1;
      object.attachmenttype = DragDropFeatures.ObjectType;
      object.objectid = DragDropFeatures.ObjectID;
      object.applicationid = 3;
      object.filesdata = UploadFileList;
      SetLoadingState(true);
      axiosAuth
        .post(
          AppContext.FilesAPI + "api/PTAttachment/SaveFilesData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            SetTriggerChild(!TriggerChild);
            let l_response = response.data.Data;
            SaveS3FilePathInDB(l_response);
          } else {
            AppContext.ShowNotification("error", response.data.Message);
          }
          SetLoadingState(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  }

  function SaveS3FilePathInDB(p_S3UploadFileList:any) {
    for (let i = 0; i < p_S3UploadFileList.length; i++) {
      console.log("Uploaded Images S3 Path: " + p_S3UploadFileList[i].S3Path); // Save this file URL in DB
    }
  }

  return (
    <div>
      {LoadingState && <LoadingAnimation />}
      <DragDrop
        ParentFeatures={DragDropFeatures}
        DragDropCallback={HandleDragDropCallback}
        TriggerChild={TriggerChild}
      />
      <button onClick={() => SaveFiles()}>Save Files</button>
    </div>
  );
}



  // UploadType == 1   "Please upload (PNG, JPG, JPEG, BMP, GIF, TIF) format images OR (Excel, Word, PDF, Powerpoint, Zipped) document files OR (.mp4, .mov) video files .";
  // UploadType == 2   "Please upload (PNG, JPG, JPEG, BMP, GIF, TIF) format images.";
  // UploadType == 3   "Please upload (Excel, Word, PDF, Powerpoint, Zipped) document files.";
  // UploadType == 4   "Please upload (.mp4, .mov) video files .";
  // UploadType == 100 "Please upload PDF files.";
