import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import AppContext from "../../Context/Context";
import axiosAuth from "../../axios/Interceptors";
import TasksComm from "./TasksComm";
import TimerManagement from "../../App/Timermanagement";
import DisplaySpentTime from "../../App/DisplaySpentTime";
import ConfirmationModal from "../../App/ConfirmationModal";
import { useGlobalState } from "../../Context/GlobalStateContext";
import { Modal } from "react-bootstrap";
import LoadingAnimation from "../../LoadingAnimation/LoadingAnimation";
import { getTimeDifferenceHHMM } from "../../Shared/Utility";
import moment from "moment";
import TimeLogDetailView from "../../App/TimeLogDetailView";

type Props = {
  MGWorkspaceID: any;
  EventDetails: any;
  handleChildClick: Function;
};
export default function EventDetailsView(props: Props) {
  const globalState = useGlobalState();
  const [loadingState, setLoadingState] = useState(false);
  const [expandView, setExpandView] = useState(false);
  const [showLogDetail, setShowLogDetail] = useState(false);

  const [filterData, setFilterData] = useState({
    MGMessageCategoryID: 8,
    MGMessageChannelID: 0,
    ShowInternalChat: true,
    ShowGeneralChat: true,
  });

  useEffect(() => {
    setFilterData((prevState: any) => ({
      ...prevState,
      ShowInternalChat: true,
      ShowGeneralChat: true,
    }));
    setShowLogDetail(false);
  }, [props.EventDetails]);

  function CreateChannel() {
    let object: any = {};
    object.typeid = 1;
    object.mgworkspaceid = props.MGWorkspaceID;
    object.mgmessagechannelid = 0;
    object.mgmessagecategoryid = filterData.MGMessageCategoryID;
    object.permissiontype = 1;
    object.title = props.EventDetails.Name;
    object.description = props.EventDetails.Description;
    object.originalchannelid = 0;
    axiosAuth
      .post(AppContext.AppBaseURL + "api/MGMessage/UpdateChannelInfo", object)
      .then((response) => {
        if (response.data.Status === 1) {
          var l_MessageChannel =
            response.data.Data[response.data.Data.length - 1];
          let MGMessageChannelID = 0;
          if (l_MessageChannel.length > 0) {
            MGMessageChannelID = l_MessageChannel[0]["MGMessageChannelID"];
            MGEventUpdateCustom(3, MGMessageChannelID);
          }
        } else {
          AppContext.ShowNotification("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function MGEventUpdateCustom(type: number, value: number) {
    let object = {} as any;
    object.mgeventid = props.EventDetails.MGEventID;
    object.type = type;
    object.value = value;
    axiosAuth
      .post(AppContext.AppBaseURL + "api/MGEvent/MGEventUpdateCustom", object)
      .then((response) => {
        if (response.data.Status === 1) {
          props.handleChildClick(8, {});
        } else {
          AppContext.ShowNotification("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function ConfirmationModalCallBack(obj: any) {
    if (obj.ID === 1) {
      if (obj.isConfirm === true) {
        MGEventUpdateCustom(obj.SelectedID, obj.OtherID);
      }
    }
    globalState.setConfirmationModalObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
      ID: 0,
      SelectedID: 0,
      OtherID: 0,
      Object: {},
    });
  }

  return (
    <>
      {loadingState && <LoadingAnimation />}

      <ConfirmationModal
        ConfirmationObject={globalState.ConfirmationModalObject}
        ConfirmationModalCallBack={ConfirmationModalCallBack}
      />

      {props.EventDetails.Name && props.EventDetails.Name != "" && (
        <div
          className={`task-detail-fix custom--scroll ${
            expandView ? "expand-view" : ""
          }`}
        >
          <div className="flex-d flex-ac flex-jsb p-all10">
            <a
              className="wh-24"
              onClick={(e) => {
                setExpandView(!expandView);
              }}
            >
              {!expandView && (
                <>
                  <img
                    title="Expand View"
                    src="/images/rightg.png"
                    alt="img"
                    width="8px"
                    height="16px"
                    className="rotate-180d"
                  />
                </>
              )}
              {expandView && (
                <>
                  <img
                    title="Compress View"
                    src="/images/rightg.png"
                    alt="img"
                    width="8px"
                    height="16px"
                  />
                </>
              )}
            </a>
            <div className="flex-d flex-ac flex-gap10">
              <a
                title="Delete"
                // onClick={() => {
                //   MGEventUpdateCustom(5, 1);
                // }}
                onClick={() =>
                  globalState.setConfirmationModalObject({
                    ShowPopup: true,
                    Heading: "Alert",
                    Message: "Are your sure, you want to delete this record?",
                    ID: 1,
                    SelectedID: 5,
                    OtherID: 1,
                  })
                }
              >
                <img
                  src="/images/delete.png"
                  alt="img"
                  width="20px"
                  height="20px"
                />
              </a>
              <a
                title="Edit"
                onClick={() => {
                  props.handleChildClick(7, props.EventDetails);
                }}
              >
                <img
                  src="/images/edit.png"
                  alt="img"
                  width="20px"
                  height="20px"
                />
              </a>
              <a
                title="Close"
                onClick={(e) => {
                  setExpandView(false);
                  props.handleChildClick(2, {});
                }}
              >
                <img
                  src="/images/icon-cross.png"
                  width="20px"
                  height="20px"
                  alt="attachment"
                />
              </a>
            </div>
          </div>
          <div className="timer-ss mt--20">
            <TimerManagement
              MGWorkspaceID={props.MGWorkspaceID}
              EventDetails={props.EventDetails}
              HandleChildClick={props.handleChildClick}
            />
          </div>
          <div className="flex-d flex-ac flex-jc time-total">
            <DisplaySpentTime
              Type={1}
              TimeSpent={props.EventDetails.ActualTimeSpent}
            />
          </div>
          <div className="flex-d flex-ac flex-jse p-all10">
            <a
              onClick={() => setShowLogDetail(!showLogDetail)}
              title="Show Logs"
            >
              <img src="/images/logs.svg" alt="img" width={28} height={28} />
            </a>
          </div>
          <TimeLogDetailView
            MGEventID={props.EventDetails.MGEventID}
            SiteUserID={0}
            MGProjectID={0}
            StartDateTime={new Date()}
            EndDateTime={new Date()}
            expandView={expandView}
            showLogDetail={showLogDetail}
          />

          {props.EventDetails.InternalChannelCode == "" && expandView && (
            <div className="flex-d modal--button flex-jc">
              <a
                onClick={() => {
                  CreateChannel();
                }}
              >
                Start Internal Chat
              </a>
            </div>
          )}
          <>
            <div className="ib-display w-100 p-lr10 font-14 temp-height mt--15">
              <span className="flex-d task-name">
                {props.EventDetails.Name}
              </span>
              <span className="flex-d task-descp">
                {props.EventDetails.Description}
              </span>
              <div className="flex-d flex-ac flex-jse flex-gap10 mt--15">
                <span className="flex-d flex-ac flex-gap5 sm-greyfont">
                  <img
                    src="/images/project.svg"
                    alt="img"
                    width={13}
                    height={13}
                  />
                  {props.EventDetails.MGProjectName}
                </span>
                <span className="flex-d flex-ac flex-gap5 sm-greyfont">
                  <img
                    src="/images/status.svg"
                    alt="img"
                    width={13}
                    height={13}
                  />
                  {props.EventDetails.MGStatusName}
                </span>
                <span className="flex-d flex-ac flex-gap5 sm-greyfont">
                  <img
                    src="/images/user.svg"
                    alt="img"
                    width={13}
                    height={13}
                  />
                  {props.EventDetails.AssignedTo}
                </span>
              </div>

              {props.EventDetails.InternalChannelCode != "" && expandView && (
                <>
                  <a
                    className="flex-d flex-jsb flex-ac m-accord"
                    onClick={(e) => {
                      let ShowInternalChat = !filterData.ShowInternalChat;
                      setFilterData((prevState: any) => ({
                        ...prevState,
                        ShowInternalChat: ShowInternalChat,
                      }));
                    }}
                  >
                    <span>Internal</span>
                    {props.EventDetails.InternalChannelCode != "" &&
                    filterData.ShowInternalChat ? (
                      <img
                        src="/images/rightg.png"
                        alt="img"
                        width="8px"
                        height="16px"
                        className="rotate-90r"
                      />
                    ) : (
                      <img
                        src="/images/downg.png"
                        alt="img"
                        width="16px"
                        height="8px"
                      />
                    )}
                  </a>
                  {props.EventDetails.InternalChannelCode != "" &&
                    filterData.ShowInternalChat && (
                      <div className="task-chat-sm">
                        <TasksComm
                          workspaceid={props.MGWorkspaceID}
                          chennelid={props.EventDetails.InternalChannelCode}
                        />
                      </div>
                    )}
                </>
              )}
            </div>

            <div className="ib-display p-lr10 w-100">
              {expandView && (
                <a
                  className="flex-d flex-jsb flex-ac m-accord"
                  onClick={(e) => {
                    let ShowGeneralChat = !filterData.ShowGeneralChat;
                    setFilterData((prevState: any) => ({
                      ...prevState,
                      ShowGeneralChat: ShowGeneralChat,
                    }));
                  }}
                >
                  <span>General</span>
                  {props.EventDetails.GeneralChannelCode != "" &&
                  filterData.ShowGeneralChat ? (
                    <img
                      src="/images/rightg.png"
                      alt="img"
                      width="8px"
                      height="16px"
                      className="rotate-90r"
                    />
                  ) : (
                    <img
                      src="/images/downg.png"
                      alt="img"
                      width="16px"
                      height="8px"
                    />
                  )}
                </a>
              )}
              {props.EventDetails.GeneralChannelCode != "" &&
                filterData.ShowGeneralChat && (
                  <div className="task-chat-sm">
                    <TasksComm
                      workspaceid={props.MGWorkspaceID}
                      chennelid={props.EventDetails.GeneralChannelCode}
                    />
                  </div>
                )}
            </div>
          </>
        </div>
      )}
    </>
  );
}
